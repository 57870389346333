<template>
  <div class="audio-object">
    <p>AUDIO - OBJECT</p>
  </div>
</template>

<script>
export default {
  name: 'AudioObject',
  // Your component options here
}
</script>

<style scoped>
.audio-object {
  
  width: 100%;
  height: 40px;
  background-color: green;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}
</style>
