<template>
  <div class="main-section">
    <!-- Sidebar component -->
    <div class="main-row">
      <sidebar v-if="$route.name !== 'browse'"/>
      <div class="main-data-col">
        <div class="right-data">
          <!-- Top bar component -->
          <top-bar> </top-bar>
          <router-view/>

        </div>
      </div>
    </div>

    <!-- Loader with transition animation -->
    <transition name="loader-animation">
      <div class="loader" v-if="show_loader">
        <img loading="lazy" src="@/assets/logo.png" alt="Filmstro Logo">
        <p id="loadingmessage">{{ loading_message }}</p>
        <!-- Loader animation SVG -->
        <svg fill="white" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <!-- SVG animation elements -->
        </svg>
      </div>
    </transition>

    <!-- Modals and components -->
    <login-modal/>
    <welcome-modal/>
    <edit-tutorial-modal/>
    <settings-modal />
    <debug-modal />
    <about-filmstro-modal />
    <premium-feature-modal :title="`Oh no, you're out of free sessions`" :emoji="`🙃`" @close="setShowNoMoreFreeSessionsModal" v-if="show_no_more_free_sessions_modal"/>
    <premium-feature-modal @close="closePremiumFeatureModal" v-if="show_premium_feature_modal"/>
    <LoginRequiredModal v-if="show_login_required_modal"/>
  </div>
</template>

<script>
// Importing necessary components, modules, and actions
import TopBar from './layout/TopBar.vue';
import Sidebar from './layout/Sidebar.vue';
import LoginModal from './components/LoginModal.vue';
import WelcomeModal from './components/Modals/WelcomeModal.vue';
import EditTutorialModal from './components/Modals/EditTutorialModal.vue';
import PremiumFeatureModal from './components/Modals/PremiumFeatureModal.vue';
import SettingsModal from './components/Modals/SettingsModal.vue';
import DebugModal from './components/Modals/DebugModal.vue';
import AboutFilmstroModal from '@/components/Modals/AboutFilmstroModal.vue';
import LoginRequiredModal from '@/components/Modals/LoginRequiredModal.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { filmstro_hotkeys } from './library/modules/FilmstroHotkeys';

export default {
  components: {
    TopBar,
    Sidebar,
    LoginModal,
    WelcomeModal,
    EditTutorialModal,
    PremiumFeatureModal,
    SettingsModal,
    DebugModal,
    AboutFilmstroModal,
    LoginRequiredModal
  },
  watch: {
    // Watch route changes for dynamic body class
    '$route': (to, from) => {
      document.body.classList.remove(from.name);
      document.body.classList.add(to.name);
    },
  },
  methods: {
    // Mapping actions and mutations to component methods
    ...mapActions(['fetchFavourites', 'fetchUserSessions']),
    ...mapMutations(['setShowNoMoreFreeSessionsModal', 'setFlyoutContentView']),
    closePremiumFeatureModal() {
      this.$store.commit('setShowPremiumFeatureModal', false);
    }
  },
  computed: {
    // Mapping getters to component computed properties
    ...mapGetters([
      'loading_message',
      'show_loader',
      'onboarding_hotspots_visible',
      'show_premium_feature_modal',
      'show_no_more_free_sessions_modal',
      'show_flyout_sidebar',
      'flyout_content_view',
      'show_login_required_modal',
      'USER_IS_LOGGED_IN'
    ]),
  },
  async mounted() {
    // Handle user logout on web app load
    if (this.USER_IS_LOGGED_IN && window.Cookie.get('SHOULD_LOGOUT_WEB_APP_USER')) {
      console.log('Logging user out...');
      await this.$store.dispatch('logout');
      this.$router.push({ name: 'browse' });
    }

    // Attempt to get user from token and verify token
    await this.$store.dispatch('maybe_get_user_from_token');
    await this.$store.dispatch('maybeVerifyToken');

    // Bind filmstro hotkeys
    filmstro_hotkeys.bind_hotkeys();

    // Show drag and drop indicator
    document.addEventListener('dragenter', function () {
      document.body.classList.add('is-dragging');
    });

    document.addEventListener('dragleave', function () {
      document.body.classList.remove('is-dragging');
    });

    // Fetch user-specific data if user is logged in
    if (this.USER_IS_LOGGED_IN) {
      await this.fetchFavourites();
      await this.fetchUserSessions();
      if (this.USER_IS_LOGGED_IN) {
        // Check if the welcome modal has been viewed before
        if (!localStorage.getItem('viewed_welcome_modal')) {
          this.$store.commit('setShowWelcomeModal', true);
        }
      }
    } else {
      return;
    }
  }
}
</script>

<style scoped>
/* Styles specific to this component */

/* Loader styles */
.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgb(32 33 42 / 70%);
  z-index: 99999;
  text-align: center;
}

#loadingmessage {
  animation: fadeanim 2s infinite;
  margin-top: 10px;
  margin-bottom: 10px;
}

@keyframes fadeanim {
  0% {
    opacity: .2;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: .3;
  }
}

/* Page transition animation styles */
.page-transition-enter-active,
.page-transition-leave-active {
  transition: 100ms ease all;
}

.page-transition-enter-from,
.page-transition-leave-to {
  opacity: 0;
  transform: translate(20px);
}

/* Loader animation transition styles */
.loader-animation-enter-active {
  transition: all .5s 1s;
}

.loader-animation-leave-active {
  transition: all .5s;
}

.loader-animation-enter,
.loader-animation-leave-to {
  opacity: 0;
}
</style>
