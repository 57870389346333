import API from "@/includes/API";
import { prepare_session_from_remote } from "@/includes/helpers";
export default {
  namespaced: true,
  state:{
    presets:[],
    current_preset:false,
    theme_name:false,
    theme_details:false,
    theme_version:"main",
    session_versions:{
      main:"Main",
      version_1:"Version 1",
      version_2:"Version 2"
    },
    session_version:"main",
    current_session:false,
    snap_sliders:false,
    audition_music:false,
    latch_mode:true,
    has_unsaved_changes:false,
    current_edit_view:"transitions",
    last_edit_link:"",
    last_edited_theme:"",
    mp3_files_loaded:false,
    rendered_themes:[],
    show_export_theme_modal:false,
    auto_save_session:true,
  },
  getters:{
    presets(state){
      let presets = [];
      if(state.theme_details.templates){
        presets = state.theme_details.templates;
      }
      return presets;
    },
    current_preset(state){
      return state.current_preset
    },
    theme_name(state){
      return state.theme_name
    },
    theme_details(state){
      return state.theme_details
    },
    current_session(state){
      return state.current_session
    },
    session_version(state){
      return state.session_version
    },
    session_versions(state){
      return state.session_versions
    },
    snap_sliders(state){
      return state.snap_sliders
    },
    audition_music(state){
      return state.audition_music
    },
    latch_mode(state){
      return state.latch_mode
    },
    has_unsaved_changes(state){
      return state.has_unsaved_changes
    },
    current_edit_view(state){
      return state.current_edit_view
    },
    mp3_files_loaded(state){
      return state.mp3_files_loaded
    },
    last_edit_link(state){
      return state.last_edit_link
    },
    last_edited_theme(state){
      return state.last_edited_theme
    },
    show_export_theme_modal(state){
      return state.show_export_theme_modal
    },
    rendered_themes(state){
      return state.rendered_themes
    },
    auto_save_session(state){
      return state.auto_save_session;
    }
  },
  actions:{
    async fetchPresets( context,theme_id=false ){
      let response;
      debugger
      if( context.rootState.user &&  context.rootState.user.user_id){
        response = await API.get(`/themes/${theme_id}/templates`).then(response=>{
          context.commit('setTemplatePresets',response)
          return response;
        }).catch( e=>{
          console.error('Something went wrong. Error ccode 1.');
        } );
      }else{

      }
      return response;
    },

    // START POINT FOR FETCHING TRACK / THEME DETAILS FROM API
    async fetchThemeDetails( context ){
      let data = false;
      try{
        if(!context.state.theme_name) return false;
        data = await API.get(`/themes/${context.state.theme_name}`).then(response=>{
          context.commit('setThemeDetails',response);
          console.log('Theme Details',response);
          return response;
        });
      }catch(error){
        data = false;
      }
      return data;
    },
    async fetchUserSession( context, session_id ){
      let response = false;
      await API.post(`/get-user-session/${session_id}`).then(data=>{
        response =  data;
        let session = prepare_session_from_remote( data );
        context.commit('setCurrentSession',session);
      })
      return response;
    },


    async saveUserSession( context, session ){
      let response = false;
      let user_id = context.rootGetters.CURRENT_USER.user_id;
      await API.post(`/user-sessions/${user_id}`,{
        session_id:session.session_id,
        data:session
      }).then(data=>{
        response = data;
        context.commit('setHasUnsavedChanges',false);
      })
      return response;
    },

    resetState(context){
      context.commit( 'setCurrentPreset',false );
      context.commit( 'setMp3FilesLoaded',false );
      context.commit( 'setHasUnsavedChanges',false);
      context.commit( 'setCurrentSession',false);
      context.commit( 'setThemeDetails',false);
      if( window.stroInterface ){
        window.stroInterface.stopPlay();
        delete window.stroInterface;
      }

      if(window.stroPlayer){
        if(window.stroPlayer?.splayer){
          // window.stroPlayer.splayer.transport.dispose();
          // window.stroPlayer.splayer.TonePlayers.dispose()
        }
        // delete window.stroPlayer;
      }
      if( window.stroDaw ){
        delete window.stroDaw;
      }
    }
  },
  mutations:{
    setTemplatePresets(state,data){
      state.presets = data;
    },
    setThemeName(state,data){
      state.theme_name = data;
    },
    setThemeDetails(state,data){
      state.theme_details = data;
    },
    setCurrentSession(state,data){
      state.current_session = data;
    },
    setSessionVersion(state,data){
      state.session_version = data;
    },
    setSnapSliders(state,data){
      state.snap_sliders = data;
    },
    setAuditionMusic(state,data){
      state.audition_music = data;
    },
    setLatchMode(state,data){
      state.latch_mode = data;
    },
    setHasUnsavedChanges(state,data){
      state.has_unsaved_changes = data;
    },
    setCurrentEditView(state,data){
      state.current_edit_view = data;
    },
    setCurrentPreset(state,data){
      state.current_preset = data;
    },
    setMp3FilesLoaded(state,data){
      if( data ){
        document.body.classList.add( 'theme-mp3-files-loaded' )
      }else{
        document.body.classList.remove( 'theme-mp3-files-loaded' )
      }
      state.mp3_files_loaded = data;
    },
    setLastEditLink(state,data){
      state.last_edit_link = data;
    },
    setLastEditedTheme(state,data){
      state.last_edited_theme = data;
    },
    setShowExportThemeModal(state,value){
      state.show_export_theme_modal = value;
    },
    addRenderedTheme(state,theme){
      state.rendered_themes.push( theme );
    },
    setRenderedThemes( state,themes ){
      state.rendered_themes = themes;
    },
    setAutoSaveSession( state,value ){
      state.auto_save_session = value;
    }
  }
};