<template>
  <div class="node-editor">
    <p>NODE-EDITOR</p>
  </div>
</template>

<script>
export default {
  name: 'NodeEditor',
  // Your component logic goes here
}
</script>

<style scoped>
.node-editor {
  
  background-color: red;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 180px;
  border-radius: 20px;

}
</style>
