import API from "@/includes/API";
import { isMinore } from "@/includes/helpers";

function filterTracksByEmotionalProfile(tracks, emotional_profile) {

  let _tracks = tracks.filter(track => {
    if (emotional_profile == 'positive') {
      return isMinore(track.key);
    }
    else if (emotional_profile == 'negative') {
      return !isMinore(track.key);
    } else if (emotional_profile == 'balanced') {
      return track.emotional_profiles.findIndex(e => e.slug.toLowerCase() == 'balanced') >= 0;
    }
    else if (emotional_profile == 'variable') {
      return track.emotional_profiles.findIndex(e => e.slug.toLowerCase() == 'variable') >= 0;
    }
    return true;
  })
  return _tracks;
}

function filterTracksByBPM(tracks, min, max) {
  min = parseInt(min);
  max = parseInt(max);

  let _tracks = tracks.filter(track => {
    let _track_bpm = parseInt(track.music_bpm);
    if (!_track_bpm) {
      return true;
    }
    if (min || max) {
      if (min && max) {
        return _track_bpm >= min && _track_bpm <= max;
      }
      if (min) {
        return _track_bpm >= min;
      }
      if (max) {
        return _track_bpm <= max;
      }
    }
    return true;
  })

  return _tracks;
}

function get_detault_browse_state() {
  return {
    search_query: "",
    search_categories: {},
    collections: [],
    categories: [],
    selected_collection: false,
    selected_album: false,
    music_cards: [],
    all_tracks: [],
    selected_theme: false,
    current_playing_theme: false,
    current_playing_theme_index: false,
    bread_crumbs: [],
    sync_music_with_video: true,
    limit_cards: 30,
    similar_track: null,
    selected_composer: null,
    music_tracks_view: 'list', //grid | list
    should_load_music_track: true,
    collections_filter_query: "",
    back_button_actions:{
      query:"",
      route:""
    },
    filters: {
      search_tags:[],
      emotional_profile: "",
      bpm: {
        min: null,
        max: null
      }
    }
  }
}



let actions = {
  maybeIncreaseCardLimit(context, value) {
    // debugger
    if (context.rootState.all_tracks) {

    }
    context.commit('setLimitCards', value);
  },
  setBrowseData(context, browse_data, a, b, c) {

    if (browse_data.collections) {
      context.commit('setCollections', browse_data.collections);
    }

    if (browse_data.categories) {
      Object.freeze(browse_data.categories);
      context.commit('setCategories', browse_data.categories);
    }
    if (browse_data.track_license_pricing) {
      Object.freeze(browse_data.track_license_pricing);

      context.commit('setTrackLicensePricing', browse_data.track_license_pricing, { root: true });
    }

    let all_tracks = get_all_tracks_from_collections(browse_data.collections);
    context.commit('setAllTracks', all_tracks);
    context.commit('setShowLoader', false, { root: true });
    window.calculateHeight();
  },
  async maybeFetchBrowseData(context, force_remote = false) {

    if (context.state.collections && context.state.collections.length && !force_remote) {
      return true;
    }
    // debugger
    let browse_data = await localforage.getItem('browse_data');
    if (!browse_data) {
      try {
        browse_data = require('../../public/data.json');
      } catch (error) {
        console.log('probably the /data.json doesnot exist?')
      }
    }
    let last_updated = 0;
    if (browse_data) {
      Object.freeze(browse_data);
      last_updated = browse_data.last_updated;
      context.dispatch('setBrowseData', browse_data);

      setTimeout(() => {
        context.dispatch('fetchBrowseData', browse_data.last_updated);
      }, 2000);

    } else {
      browse_data = await context.dispatch('fetchBrowseData', last_updated);
    }
  },
  async fetchBrowseData(context, last_updated = 0) {
    if (last_updated <= 0) {
      context.commit('setShowLoader', true, { root: true });
      context.commit('setLoaderMessage', 'Loading Filmstro Collections!', { root: true });
    }
    return await API.get(`/browse?last_updated=${last_updated}`).then(response => {
      if (response.status == 'success') {
        context.dispatch('setBrowseData', response.data);

        localforage.setItem('browse_data', response.data).then(function () {
          return localforage.getItem('key');
        }).catch(function (err) {
          // we got an error
        });
        context.commit('setShowLoader', false, { root: true });
        return response.data;
      }

    }).catch(e => {
      console.error('Something went wrong. Error ccode 1.');
    });
  },
  setDefaultCollectionAndFeaturedPlaylists( context, args = false ){

    let _default_collection = context.state.collections.find( col=>col.name == "new" );
    if( _default_collection ){
      context.commit('setSelectedCollection', _default_collection)
      context.commit('setMusicCards', _default_collection.tracks);
      context.commit('setSelectedAlbum', false);
      context.commit('setSearchQuery', "" );
      context.commit('setBreadCrumbs', []);
      // context.commit('browse/setCurrentPlayingTheme', false);
    }
  }
}

let getters = {
  getSearchQuery(state) {
    return state.search_query;
  },
  collections_filter_query(state) {
    return state.collections_filter_query;
  },
  breadcrumbs(state) {
    return state.bread_crumbs;
  },
  search_categories(state) {
    return state.search_categories;
  },
  sync_music_with_video(state) {
    return state.sync_music_with_video
  },
  limit_cards(state) {
    return state.limit_cards
  },
  should_load_music_track(state) {
    return state.should_load_music_track
  },

  collections(state) {
    return state.collections;
  },
  categories(state) {
    return state.categories;
  },

  selected_collection(state) {
    return state.selected_collection;
  },
  selected_album(state) {
    return state.selected_album;
  },
  playlists( state ) {
    let query = state.search_query.trim().toLowerCase();
    let playlists = [];
    if (query.length) {
      let _query = query.split(/,| /).join('|');
      // let albums = []
      let collections = state.collections;
      for (let index = 0; index < collections.length; index++) {
        let albums = collections[index].albums.forEach(album => {
          let title_search        = album.title.toLowerCase().match(_query);
          let description_text    = false
          let search_terms_search = false;
          if( !title_search ){
            search_terms_search = album.search_terms.filter(term => { return term.name.toLowerCase().match(_query) }).length;
            if( !search_terms_search){
              description_text    = album.description.toLowerCase().match( _query );
            }
          }

          if (title_search || search_terms_search || description_text) {
            let al = JSON.parse(JSON.stringify(album));
            //add the weitgh system
            if( title_search ){ al.weight = 1;
            al.matched_key = title_search }
            if( search_terms_search ){ al.weight = .5;
            al.matched_key = search_terms_search }
            if( description_text ){
              al.weight = .2;
              al.matched_key = description_text
              if( description_text['index'] ){
                al.weight = .2 - ( description_text['index'] * 0.00001 );
                // we apply a wieght based on where the position of the keyword
              }
            }

            // al.collection = collections[ index ].name;
            playlists.push( al );
          }

          return false;
        });
        // playlists = playlists.concat( albums );
      }

    }

    // sorting them by weight
    window.playlists = playlists;
    playlists = playlists.sort((a,b)=> (a.weight < b.weight ? 1 : -1))
    playlists = [...new Map(playlists.map(item => [ item['id'], item])).values()]
    return playlists;
  },
  featured_playlists(state){
    let featured_collection = state.collections.find( col=>col.name== "featured" );
    if( featured_collection && featured_collection.albums ){
      return JSON.parse( JSON.stringify( featured_collection.albums ) );
    }else{
      return [];
    }
},
  music_cards(state, getters, rootState, rootgetters, aa, bb) {
    let query = state.search_query.trim().toLowerCase();
    console.log(query);
    let _tracks = [];
    if (state.selected_collection && state.selected_album) {
      _tracks = state.selected_album.tracks;
    }
    else if (state.selected_collection && state.selected_collection.data_type == 'tracks') {
      _tracks = state.selected_collection.tracks
    }


    let cats = Object.keys(state.search_categories).filter(d => {
      return state.search_categories[d];
    });


    // Filter tracks by selected composer
    if (typeof state.selected_composer !== 'undefined' && state.selected_composer) {
      _tracks = state.all_tracks.filter(track => {
        if (typeof state.selected_composer !== 'undefined') {
          return track.composer.id == state.selected_composer.id;
        }
      })
    }

    let emotional_profile = state.filters.emotional_profile;

    if (query.length) {
      let _query = query.split(/,| /).join('|');
      console.log('QQQ',_query)
      // let _query = state.filters.search_tags.map(a=>a.value.toLowerCase().trim()).join("|")
      _tracks = state.all_tracks.filter((track) => {
        track.weight = 0;
        let title_search = track.title.toLowerCase().match(_query);
        let search_terms_search = track.search_terms.filter(term => { return term.name.toLowerCase().match(_query) })
        let composer_search = track.composer.name.toLowerCase().match(_query);
        if( title_search ){
          track.weight = 1 - ( title_search['index'] * 0.001 );
        }
        if( search_terms_search.length ){
          track.weight = .75;
        }
        if( composer_search ){
          track.weight = .5;
        }
        if (title_search || search_terms_search.length || composer_search) {
          return true;
        }
        // return  category_search || title_search || search_terms_search.length;
        return false;
      })
    }

    if (cats.length) {
      _tracks = _tracks.filter(track => {
        return track.category.name && cats.includes(track.category.name);
      })
    }
    // console.log(`Total Tracks ${_tracks.length}`);
    _tracks.filter(track => { return track.clear_music.length && track.watermark_music.length; })


    let has_emotional_profile = false;

    if (emotional_profile) {
      _tracks = filterTracksByEmotionalProfile(_tracks, emotional_profile);
    }

    if (state.filters.bpm) {
      let min = parseInt(state.filters.bpm.min);
      let max = parseInt(state.filters.bpm.max);
      _tracks = filterTracksByBPM(_tracks, min, max);
    }

    // return _tracks;
    return _tracks.sort((a,b)=> (a.weight < b.weight ? 1 : -1)).slice(0, state.limit_cards)
  },
  current_playing_theme(state) {
    return state.current_playing_theme;
  },
  current_playing_theme_index(state) {
    return state.current_playing_theme_index;
  },
  can_play_previous_song(state) {
    return state.current_playing_theme_index > 0;
  },
  can_play_next_song(state) {
    return state.current_playing_theme_index >= 0 || state.current_playing_theme_index < state.music_cards.length;
  },
  selected_theme(state) {
    return state.selected_theme;
  },
  similar_track(state) {
    return state.similar_track;
  },
  selected_composer(state) {
    return state.selected_composer;
  },
  music_tracks_view(state) {
    return state.music_tracks_view;
  },
  filters(state) {
    return state.filters;
  },
  emotional_profile(state) {
    return state.filters.emotional_profile;
  },
  minimum_bpm(state) {
    return state.filters.bpm.min;
  },
  maximum_bpm(state) {
    return state.filters.bpm.max;
  },
  back_button_actions(state){
    return state.back_button_actions;
  },
  has_filters_enabled(state){
    let has_categories = Object.values(state.search_categories).filter(a=>a).length>0;
    let has_emotional_profile = state.filters.emotional_profile.length >0;
    let has_bpm = !!state.filters.bpm.min || state.filters.bpm.max;
    return has_categories || has_emotional_profile || has_bpm;
  }
}

let mutations = {
  setCollections(state, collections) {
    state.collections = collections;
  },
  setCategories(state, categories) {

    state.categories = categories;
    let _cats = {};
    categories.forEach(cat => {
      _cats[cat.name] = false;
    })
    state.search_categories = _cats;
  },
  setSelectedAlbum(state, album) {
    state.selected_album = album;
  },

  setSelectedCollection(state, collection) {
    state.selected_collection = collection;
  },

  setCurrentPlayingTheme(state, theme) {
    state.current_playing_theme = theme;
    let index = state.music_cards.findIndex(card => { return card.id == theme.id; });
    state.current_playing_theme_index = index
  },
  setAllTracks(state, data) {
    var unique = [];
    var distinct = [];
    for (let i = 0; i < data.length; i++) {
      if (!unique[data[i].theme_id]) {
        distinct.push(data[i]);
        unique[data[i].theme_id] = 1;
      }
    }
    state.all_tracks = distinct;
  },
  setSearchQuery(state, query) {
    state.search_query = query
  },
  setCollectionsSearchQuery(state, query) {
    state.collections_filter_query = query;
  },
  setSearchCategories(state, cats) {
    state.search_categories = cats
  },
  toggleSearchCategory(state, cat) {
    state.search_categories[cat] = !state.search_categories[cat];
  },
  toggleEmotionalProfileFilter(state, profile) {
    if (state.filters.emotional_profile == profile) {
      state.filters.emotional_profile = ""
    } else {
      state.filters.emotional_profile = profile;
    }
  },
  addSearchTags(state,tags){
    if( tags.length ){
      state.filters.search_tags = tags
    }
  },
  resetSearchCategories(state) {
    Object.keys(state.search_categories).forEach(d => {
      state.search_categories[d] = false;
    });
  },
  setBreadCrumbs(state, bread_crumbs) {

    state.bread_crumbs = bread_crumbs
  },
  resetState(state) {
    let x = get_detault_browse_state()
    Object.assign(state, x);
  },
  toggleSyncMusicWithVideo(state) {
    state.sync_music_with_video = !state.sync_music_with_video;
  },
  setLimitCards(state, value) {
    state.limit_cards = value;
  },
  setShouldLoadMusicTrack(state, value) {
    state.should_load_music_track = value;
  },
  resetTrackCardsLimit(state, value) {
    let defaults = get_detault_browse_state()
    state.limit_cards = defaults.limit_cards;
  },
  setSelectedTheme(state, theme) {
    state.selected_theme = theme;
  },
  setMusicCards(state, tracks) {
    state.music_cards = tracks;
  },
  setSimilarTrack(state, track) {
    state.similar_track = track;
  },
  setSelectedComposer(state, composer) {
    state.selected_composer = composer;
  },
  setMusicTracksView(state, view) {
    state.music_tracks_view = view;
  },
  setComposerBySlug(state, slug) {
    if (slug) {
      let _track = state.all_tracks.find(track => {
        return track.composer.slug == slug;
      })
      if (typeof _track !== 'undefined' && _track.composer) {
        state.selected_composer = _track.composer;
      }
    }
  },
  setMinimumBPM(state, bpm) {
    state.filters.bpm.min = bpm;
  },
  setMaximumBPM(state, bpm) {
    state.filters.bpm.max = bpm;
  },
  setRouteForBackButton( state, path){
    state.back_button_actions.route = path;
  },
  setQueryForBackButton(state,q){
    state.back_button_actions.query = q;
  }


}




function get_all_tracks_from_collections(collections) {
  let tracks = [];
  let track_ids = [];
  collections.forEach(d => {
    if (d.tracks) {
      d.tracks.forEach(t => {
        if (!track_ids.includes(t.id) && t.clear_music.length && t.watermark_music.length) {
          tracks.push(t);
          track_ids.push(t.id);
        }
      })
    }
    if (d.albums) {
      d.albums.forEach(a => {
        a.tracks.forEach(tt => {
          try {
            if (!track_ids.includes(tt.id) && tt.clear_music.length && tt.watermark_music.length) {
              tracks.push(tt);
              track_ids.push(tt.id);
            }
          } catch (e) {
            console.log(e)
          }
        })
      })
    }
  });
  track_ids = null;
  return tracks;
}




export default {
  namespaced: true,
  state: get_detault_browse_state(),
  getters,
  mutations,
  actions
}

