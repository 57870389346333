<template>
  <div class="video-box-container" :class="{ 'no-bg': video_source.length }">


    <div class="video-box-wrapper"
      av-tippy="{  content: `You can add an mp4 video which will play back synchronised to each track you preview while you're browsing music. <span>Tip:</span> Videos are loaded into your browser's temporary storage, not uploaded to Filmstro.`, placement: 'bottom'}">

      <div v-show="!video_source.length" class="no-video-wrapper" @click="openUploadDialogue"
        av-tippy="{ followCursor:true,content:`You can add an mp4 video so you can make musical choices based on your edit. <br><span class='filmstro-yellow'>Tip:</span> Latest version of Chrome required. For maximum performance, close other browsers and web apps. Max file size 500 MB.`,placement:'right'}">
        <!-- <span>No clip in Video Preview </span> -->
        <!-- <span v-html="$t('upload_video')"></span> -->
      </div>


      <div class="video-box" v-show="video_source.length">
        <video key="the_video_element" :muted="video_is_muted" @loadedmetadata="onLoadedMetaData" @ended="onVideoEnded"
          preload="auto" @play="onPlay" @pause="onPause" :src="video_source" @timeupdate="handleTimeUpdate()"
          id="video-el2">
          <!-- <source :src="video_source" type="video/mp4"  /> -->
        </video>

        <div class="video-controls" :class="{ 'show-controls': video_is_playing }">
          <button class="video-play-toggle clear-button" @click="toggleVideo" v-if="false">
            <filmstro-icon v-show="video_is_playing" name="pause-icon" />
            <filmstro-icon v-show="!video_is_playing" name="play-icon" />
          </button>
          <div v-if="false" @mouseup="handleProgressMouseUp()" @mousemove="handleProgressMouseMove($event)"
            @mousedown="handleProgressMouseDown($event)" class="video-progress">
            <div ref="video_progress" class="video-progress--filled"></div>
          </div>
          <div class="video-time-wrapper"><time ref="video_time" class="video-time"></time> / <time ref="video_duration"
              class="video-duration"></time></div>

          <div class="volume-control">
            <range-slider @input="setVideoVolume($event.target.value)" class="video-volume-slider" value="100" min="0"
              max="100" />
          </div>

          <button class="video-mute-toggle clear-button" @click="toggleMute">
            <filmstro-icon name="speaker-normal" v-show="!video_is_muted" />
            <filmstro-icon name="speaker-muted" v-show="video_is_muted" />
          </button>
        </div>

      </div>

    </div>

    <div class="video-box-footer">
      <button
        v-tippy="{ content: `Turn this on to watch your video while you're browsing. You can also control the video volume separately or mute it entirely to focus on the music without hearing your VO, foley or SFX.`, placement: 'top' }"
        class="clear-button sync-music-button" @click="toggleMusicSync"
        v-if="$route.name == 'browse' && video_source.length || onboarding_hotspots_visible">
        <filmstro-icon v-show="sync_music_with_video" name="link-active" />
        <filmstro-icon v-show="!sync_music_with_video" name="link" />
      </button>
      <button class="load-video-button" :class="{ 'is-showing': show_video_options }"
        @mousemove="onVideoOptionsMouseMove($event)"
        v-tippy="{ followCursor: 'vertical', content: `You can add an mp4 video so you can make musical choices based on your edit. <br><span class='filmstro-yellow'>Tip:</span> Latest version of Chrome required. For maximum performance, close other browsers and web apps. Max file size 500 MB.`, placement: 'right' }"
        @click="show_video_options = !show_video_options"
        v-if="video_source.length"> <!-- Add this line -->
        <filmstro-icon name='add-video' class="no-click add-video" />
        <filmstro-icon name='add-video-active' class="add-video-active no-click " />

        <div class="video-options" v-show="show_video_options">
          <div class="video-option" @click="openUploadDialogue">Load New video</div>
          <div v-if="video_source.length" class="video-option" @click="deleteCurrentVideo">Delete current video</div>
        </div>
      </button>
      <input @change="onFileUploadChange" hidden ref="video_upload_file" type="file" accept="video/mp4">

    </div>

  </div>
</template>

<script>
import DragDropit from '@/includes/DragDropit';
import { mapGetters, mapMutations } from 'vuex';
import RangeSlider from '../components/RangeSlider.vue'
import { format_size } from '@/includes/helpers';
import OffsetTimeline from './OffsetTimeline.vue';
export default {
  data() {
    return {
      video_is_playing: false,
      video_is_muted: false,
      file_details: false,
      mouse_down: false,
      show_video_options: false,
      observer: null
    }
  },
  components: {
    OffsetTimeline,
    RangeSlider
  },
  computed: {
    ...mapGetters(['video_source', 'video_file_info', 'video_element', 'onboarding_hotspots_visible']),
    ...mapGetters('browse', ['sync_music_with_video']),
    ...mapGetters('edit', ['mp3_files_loaded']),
    formatSize() {
      if (!this.file_details.size) return 0;
      return format_size(this.file_details.size);
    },
  },
  methods: {

    onVideoOptionsMouseMove(e) {
      if (!this.show_video_options) return;
      if (e.offsetX < -250 || e.offsetY > 450 || e.offsetY < -250) {
        this.show_video_options = false;
      }
    },
    ...mapMutations(['setVideoSource']),
    onPlay() {
      this.video_is_playing = true;
    },
    onPause() {
      this.video_is_playing = false;
    },
    handleTimeUpdate() {
      if (this.$refs.video_progress) {
        const percent = this.video_element.currentTime / this.video_element.duration * 100;
        this.$refs.video_progress.style.flexBasis = `${percent}%`
      }
      this.set_video_time();
    },
    toggleVideo() {
      if (this.video_element.paused) {
        this.video_element.play();
        this.video_is_playing = true;
      } else {
        this.video_element.pause();
        this.video_is_playing = false;
      }
    },
    onLoadedMetaData() {
      this.set_video_time();
      this.set_video_duration();
    },
    onVideoEnded() {
      this.video_element.currentTime = 0;
      this.set_video_time();
      this.set_video_duration();
      this.video_is_playing = false;
    },
    set_video_time() {
      let video_time = "00:00";
      if (this.video_element.readyState > 0) {
        let seconds = Math.floor(this.video_element.currentTime % 60);
        let minutes = Math.floor(this.video_element.currentTime / 60);
        seconds = seconds >= 10 ? seconds : '0' + seconds;
        video_time = `${minutes}:${seconds}`;
      }
      if (this.$refs.video_time) {
        this.$refs.video_time.innerHTML = video_time;
      }
    },
    set_video_duration() {
      let duration = "00:00";
      if (this.video_element.readyState > 0) {
        if (this.video_element.duration == 'Infinity') {
          duration = "";
        } else {

          let seconds = Math.floor(this.video_element.duration % 60);
          let minutes = Math.floor(this.video_element.duration / 60);
          seconds = seconds >= 10 ? seconds : '0' + seconds;
          duration = `${minutes}:${seconds}`;
        }
      }
      if (this.$refs.video_duration) {
        this.$refs.video_duration.innerHTML = duration;
      }
    },
    handleProgressMouseMove(event) {
      if (!this.mouse_down) { return; }
      this.setVideoProgress(event);
    },
    handleProgressMouseUp() {
      this.mouse_down = false;
    },
    handleProgressMouseDown(event) {
      this.mouse_down = true;
      this.setVideoProgress(event);
    },
    setVideoProgress(event) {
      const scrubTime = event.offsetX / document.querySelector('.video-progress').offsetWidth * this.video_element.duration;
      this.video_element.currentTime = scrubTime
    },

    setVideoVolume(value) {
      // if( this.video_element.muted  ){
      //   }
      this.video_is_muted = false;
      this.video_element.muted = false;
      this.video_element.volume = value / 100;

    },
    toggleMute() {
      if (this.video_element.muted) {
        this.video_element.muted = false;
        this.video_is_muted = false;

      } else {
        this.video_element.muted = true;
        this.video_is_muted = true;
      }
    },
    toggleMusicSync() {
      if (!this.video_source.length) {
        return;
      }
      this.$store.commit('browse/toggleSyncMusicWithVideo');
    },
    deleteCurrentVideo() {
      this.show_video_options = false;
      this.$store.commit('setVideoSource', "");
      this.$store.commit('setVideoFileInfo', { name: "", size: "" });
    },
    openUploadDialogue() {
      this.show_video_options = false;
      this.$refs.video_upload_file.click();
    },
    onFileUploadChange(event) {

      this.handleVideoUpload(event.target.files);
    },
    handleVideoUpload(files) {
  const file = files[0];
  let _this = this;

  this.file_details = {
    name: file.name,
    size: file.size
  };
  this.$store.commit('setShowLoader', true);
  this.$store.commit('setLoaderMessage', `Loading ${this.file_details.name}`, 'onpresetSet');
  this.$store.commit('setVideoFileInfo', this.file_details);

  var localUrl = (URL || webkitURL).createObjectURL(file);

  _this.$store.commit('setVideoSource', localUrl)
  this.video_element.src = localUrl;
  setTimeout(() => {
    _this.$store.commit('setShowLoader', false);
  }, 350);

  this.$notification.success('Added', `Video ${this.file_details.name} was added.`);

  // Clear the file input
  this.$refs.video_upload_file.value = '';
},
    makeVideoStickyOnScroll(entries) {
      // Loop through all the observed elements
      if (this.video_source && this.video_source.length)
        for (let entry of entries) {
          // wen need to hide the video mini
          if (entry.isIntersecting) {
            entry.target.classList.add('is-becoming-sticky-video')
            setTimeout(() => {
              entry.target.classList.remove('is-becoming-sticky-video')
              entry.target.classList.remove('is-sticky-video')
            }, 200);
          } else {
            entry.target.classList.add('is-becoming-sticky-video')
            setTimeout(() => {
              entry.target.classList.add('is-sticky-video')
              entry.target.classList.remove('is-becoming-sticky-video')
            }, 400);
          }
        }

    }
  },
  unmounted() {
    if (this.video_element) {
      this.video_element.pause();
      this.video_is_playing = false;
      this.video_is_muted = true;
      this.video_element.removeEventListener('play', this.onPlay);
      this.video_element.removeEventListener('pause', this.onPause);
      this.video_element.removeEventListener('timeupdate', this.handleTimeUpdate);
      this.video_element.removeEventListener('loadedmetadata', this.onLoadedMetaData);
      this.video_element.removeEventListener('ended', this.onVideoEnded);
    }
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  mounted() {
    setTimeout(() => {
      let v = document.getElementById('video-el2');
      this.$store.commit('setVideoElement', v);
      this.onLoadedMetaData();
    }, 300);
    if (!this.video_source.length) {
      // this.$store.commit('setVideoSource','https://staging-app.filmstro.com/video/test-video.mp4');

      // this.$store.commit('setVideoSource','https://github.com/gregoriB/custom-HTML5-video-player-Javascript/blob/master/video.mp4?raw=true');
      // this.$store.commit('setVideoFileInfo', {
      //   name:'Demo',
      //   size:123123
      // } );

      // setTimeout(() => {
      //   this.$store.commit('setVideoElement', document.querySelector('#video-el2'));
      //   this.onLoadedMetaData();
      // }, 300);
    } else {

      this.video_element.currentTime = 0;

    }

    // observer  if the videowrapper is on view or not
    this.observer = new IntersectionObserver(this.makeVideoStickyOnScroll, {
      rootMargin: "-100px"
    });
    this.observer.observe(document.querySelector('.video-box-wrapper'))



    let drop_area = new DragDropit('.video-box-wrapper');
    if (drop_area !== 'undefined' && drop_area.element) {

      drop_area.element.addEventListener('dropit.drop', (event) => {
        if (event.detail) {
          if (event.detail[0].type.includes('video')) {
            this.handleVideoUpload(event.detail)
          } else {
            this.$notification.error('Error', 'File format is wrong. Please upload a video');
          }
        }
      });

    }
  }
}
</script>

<style scoped lang="scss">




.video-box-container {
    display: flex;
    padding: 2vw; 
    background-image: url(@/assets/images/film-image.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    width: 630px;
    max-width: 630px;

    &.no-bg {
        background-image: none;
    }

    @media screen and (max-width: 1250px) {
        width: 400px;
        min-width: 400px;
        margin-top: 80px;
        
    }

    @media screen and (max-width: 900px) {
        display: none;
    }
}
.video-box-footer {
  position: relative;

  /* Add a pseudo-element to prevent height collapse */
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

.load-video-button {

  border: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  margin-left: auto;
  position: absolute;
  top: 89%;
  left: 40%;
 
  

  .add-video-active {
    display: none;
  }

  &:hover {
    .add-video-active {
      display: block;
    }

    .add-video {
      display: none;
    }

  }
}

.video-box-container video {
  width: 100%;
  height: auto;
  max-height: 50vh; /* proportional max height */
}

.video-box-wrapper {
  // min-height: 250px;
  height: 100%;
  width: 100%;
  padding: 10px;
  transition: all .5s ease;
}


.no-video-wrapper {
  max-width: 320px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 290px;
  min-height: 180px;
  text-align: center;

  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    outline: dashed 1px white;
  }
}



.no-video-wrapper span {
  color: #9B9B9B;
  font-size: 0.875rem;
}


.sync-music-button {
  width: 32px;
}

.video-box {
  flex: 1;
  position: relative;
  transition: all .5s ease;

  &:hover {
    .video-controls {
      opacity: 1;
      visibility: visible;
    }
  }
}

/* Video Controls */
.video-controls {
  background: rgba(0, 0, 0, 0.5);
  border-top: 5px solid rgba(0, 0, 0, 0.02);
  border-bottom: 5px solid rgba(0, 0, 0, 0);
  display: flex;
  height: 40px;
  justify-content: space-around;
  align-items: center;
  opacity: 0;
  margin: 0;
  transition: .5s;
  transition-property: opacity, visibility;
  // transform: translateY(-39px);
  visibility: hidden;
  width: 100%;
  padding: 5px 10px;
  gap: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  &.show-controls {
    opacity: 1;
    visibility: visible;
  }

}

.video-progress {
  flex: 10;
  position: relative;
  display: flex;
  flex-basis: 100%;
  height: 15px;
  transition: height 0.3s;
  background: var(--filmstro-black);
  cursor: ew-resize;
}

.video-progress--filled {
  width: 50%;
  background: var(--filmstro-yellow);
  flex: 0;
  flex-basis: 00%;
}

video {
  display: block;
  // object-fit: cover;
  margin: 0 auto;
  transition: opacity .5s;
  width: 100%;
}

.volume-control {
  width: 100px;
  max-width: 80px;
  margin-left: auto;
  position: relative;
  display: flex;
  flex-basis: 100%;
  // height: 5px;
  transition: height 0.3s;
  background: var(--filmstro-black);
  cursor: ew-resize;
}


.video-time-wrapper {
  font-size: 12px;
  width: auto;
  display: flex;
  gap: 3px;
}

.video-play-toggle img {
  width: 25px;
  height: auto;
}

.video-mute-toggle img {
  width: 25px;
  height: auto;
}

.video-options {
  position: absolute;
  width: 160px;
  left: -200px;
  background: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  border: solid var(--filmstro-white-fade);
  z-index: 10000;
}

button.load-video-button {
  position: relative;
}

button.load-video-button.is-showing:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  background: transparent;
  width: 100%;
  height: 100%;
}

.video-option {
  padding: 10px;
}

.video-option:hover {
  background: var(--filmstro-black);
  color: white;
}
</style>

<style lang="scss">
.volume-control .range-slider-wrapper {
  width: 100%;
  height: auto;

  input {

    margin: 0 !important;
    height: 5px;
    // background-color: transparent!important;

    &::-webkit-slider-thumb {
      width: 15px !important;
      height: 15px !important;
    }
  }
}



.video-box-wrapper.is-sticky-video .video-box {
  // position: fixed;
  // top: 100px;
  // right: 0;
  // z-index: 999;
  // max-width: 300px;
  // transform: scale(1);

  video {
    border-radius: 5px;
  }
}

.video-box-wrapper.is-sticky-video {
  z-index: 9999999;
}

.is-becoming-sticky-video .video-box {
  position: fixed;
  top: 130px;
  right: -500px;
  z-index: 99999999;
  transition: all .4s ease;
  // transform: scale(0);
}</style>