import { useStore } from "vuex";
import { api_base_url } from "./helpers";

export class API  {
    headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
    constructor( base="" ){

        let a = useStore()
        if( base.length ){
            this.base = base;
        }else{
            this.base = api_base_url();//  "https://development.filmstro.com/wp-json/api/v1/web-app";
        }
    }


    post =  async ( endpoint, data=null ,return_type = 'json' )=>{
        return await this.request('POST',endpoint,data,return_type);
    }
    get =  async ( endpoint, data=null )=>{
        return await this.request('GET',endpoint,data);
    }
    delete = async ( endpoint, data=null )=>{
        return await this.request('DELETE',endpoint,data);
    }
    request = async ( method,endpoint,data,return_type = 'json' )=>{
        if (!endpoint.includes('http')) {
            endpoint = `${this.base}${endpoint}`
        }
        let config = {
            method: method,
            headers:this.headers
        };
        if( data ){
            config.body = JSON.stringify( data );
        }
        let response = await fetch(endpoint, config )
        let _data;

        if( return_type == 'text' ){
            return response.text();
        }
        try{
            _data =response.json()
        }catch(e){
            _data =response.text()
        }
        return _data;
        // if( res )
    }
}

const api = ()=>{
    let api = new API();
    return api;
}
export default api();